<template>
  <v-card flat height="100%" class="rounded-lg pa-4">
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="calendarType"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:date="getEventsDay"
        @click:event="showEvent"
      ></v-calendar>
      <event-menu
        :key="componentKey"
        :showMenu="selectedOpen"
        :event="selectedEvent"
        :element="selectedElement"
      ></event-menu>
    </v-sheet>
  </v-card>
</template>

<script>
import moment from "moment";

import { EVENT_CALENDAR_COMPONENT_EVENT_BUS } from "./js/eventCalendarComponentEventBus";
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const eventRepository = RepositoryFactory.get("event");

import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("eventModule");

import { convertUtcToLocal, setToUtc } from "@/services/helpers/date";

export default {
  components: {
    EventMenu: () => import("../menus/EventMenu.vue"),
  },
  data: () => ({
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    events: [],
    /**
     * number of colors must be same as the number of locations
     */
    tags: [
      { color: "#3F51B5" }, //waikele
      { color: "#D81B60" }, //mililani
      { color: "#7CB342" }, //honolulu
      { color: "#FF6D00" }, //metro manila
      { color: "#009688" }, //online
      { color: "#E7F249" }, //kailua
      { color: "#8C0F7C" }, //waimea
    ],
    cachedEvents: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    componentKey: 0,
  }),
  computed: {
    ...mapGetters({
      now: 'now',
      calendarType: 'calendarType'
    })
  },
  watch: {
    value: function(newValue) {
      this.$store.commit('eventModule/SET_NOW', moment(newValue));
    },
  },
  methods: {
    getEvents({ start, end }) {
      let dateStartAllowance = setToUtc(start.date).subtract(1, "days").format("YYYY-MM-DD");
      let dateEndAllowance = setToUtc(end.date).add(1, "days").format("YYYY-MM-DD");
      console.log({ dateStartAllowance, dateEndAllowance, start, end })
      let params = {
        start_date: dateStartAllowance,
        end_date: dateEndAllowance,
        per_page: 9999,
      };

      // let params = {
      //   start_date: start.date,
      //   end_date: end.date,
      //   per_page: 9999,
      //   // date: start.date
      // };

      eventRepository
        .get({ params })
        .then((response) => {
          let {
            data: {
              responseData: { data: items },
            },
          } = response;

          let events = items.map((item) => {
            const defaultColor = "#000000";

            let tag = item.location_id
              ? this.tags[item.location_id - 1]
              : defaultColor;

            /**
             * Set to moment UTC instance
             * 
             * Note for checking:
             * UTC to Manila time = 8hrs difference
             */

            return {
              id: item.id,
              name: item.name,
              location: item.location,
              location_id: item.location_id,
              notes: item.notes,
              start: convertUtcToLocal(item.event_at).format('YYYY-MM-DD HH:mm:ss'),
              end: convertUtcToLocal(item.event_end_at).format('YYYY-MM-DD HH:mm:ss'),
              color: tag?.color || defaultColor,
              timed: item.event_end_at ? true : false,
              // tag: tag.value,
              tag: item.location_id,
              type: item.type,
            };
          });

          this.events = events;
          
          this.cachedEvents = events;
        })
        .catch((error) => {
          console.log({ error })
          this.events = [];
        });
    },
    getEventColor(event) {
      return event.color;
    },
    previousMonthEventBus() {
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$off("previousMonth");
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$on("previousMonth", () => {
        this.$refs.calendar.prev();
      });
    },
    nextMonthEventBus() {
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$off("nextMonth");
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$on("nextMonth", () => {
        this.$refs.calendar.next();
      });
    },
    filterCalendarEventsEventBus() {
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$off("filterCalendarEvents");
      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$on("filterCalendarEvents", (data) => {
        let events = [];

        if (data.search) {
          events = this.cachedEvents
            .filter((item) => item.name.toLowerCase().indexOf(data.search) > -1)
            .filter((item) => !data.notSelectedTags.includes(item.tag));

          this.events = events;

          return;
        }

        if (data.notSelectedTags) {
          events = this.cachedEvents.filter(
            (item) => !data.notSelectedTags.includes(item.tag)
          );

          this.events = events;

          return;
        }
      });
    },
    reloadItemsEventBus() {
      GLOBAL_EVENT_BUS.$off("reloadItems_eventCalendar");
      GLOBAL_EVENT_BUS.$on("reloadItems_eventCalendar", (data) => {
        let params = {
          start: {
            date: moment(data).startOf("month").format("Y-MM-DD"),
          },
          end: {
            date: moment(data).endOf("month").format("Y-MM-DD"),
          },
        };

        this.getEvents(params);
      });
    },
    deleteReloadItemsEventBus() {
      DELETE_ITEM_EVENT_BUS.$off("reloadItems_eventCalendar");
      DELETE_ITEM_EVENT_BUS.$on("reloadItems_eventCalendar", () => {
        this.selectedOpen = false;
        this.componentKey++;

        let params = {
          start: {
            date: moment().startOf("month").format("Y-MM-DD"),
          },
          end: {
            date: moment().endOf("month").format("Y-MM-DD"),
          },
        };

        this.getEvents(params);
      });
    },
    setupEventBus() {
      this.previousMonthEventBus();
      this.nextMonthEventBus();
      this.filterCalendarEventsEventBus();
      this.reloadItemsEventBus();
      this.deleteReloadItemsEventBus();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.componentKey++;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventsDay() {
      this.$store.commit('eventModule/SET_CALENDAR_TYPE', 'day')
    }
  },
  mounted() {
    this.setupEventBus();

    /**
     * Reference for converting UTC - Local Time
     */

    // let db_date = "2021-07-04 03:45:00"; //dates on database are in UTC format

    // let toUTC = moment.utc(db_date)
    // let toLocal = moment(toUTC).local();

    // let helper = convertUtcToLocal(db_date);

    // console.log({
    //   db_date,
    //   utc: toUTC.format("YYYY-MM-DD HH:mm:ss"),
    //   local: toLocal.format("YYYY-MM-DD HH:mm:ss"),
    //   helper: helper.format("YYYY-MM-DD HH:mm:ss"),
    //   diff: toLocal.diff(toUTC.format("YYYY-MM-DD HH:mm:ss"), 'hours')
    // })
  },
};
</script>
